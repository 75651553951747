import React, { useState, useEffect } from 'react';
import './Home.css';
import Preloader from '../Preloader/Preloader'; // Importa o Preloader corretamente
import backgroundImage from '../../assets/images/1.png'; // Caminho ajustado para a imagem

const Home = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 800); // Tempo de carregamento ajustável

    return () => clearTimeout(timer);
  }, []);



  return (
    <section className="home">
      <div className="home-content">
        <div className="home-text">
<h1 class="home-title">
  Simplifique Seus Pagamentos com um Gateway 
   <span class="highlight">Sem Bloqueios.</span>
</h1>
          <p>Impulsione suas vendas com soluções de pagamento rápidas e seguras, garantindo mais eficiência em cada transação.</p>
        </div>
        <div className="home-image">
          <img src={backgroundImage} alt="Imagem de fundo" />
        </div>
      </div>
    </section>
  );
};

export default Home;
