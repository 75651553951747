import React, { useEffect, useState } from 'react';
import './Bloco9_3.css';
import sb from '../../assets/images/sb.png';
import cb from '../../assets/images/cb.png';



const Bloco9_3 = () => {
  const [isWithBitwoei, setIsWithBitwoei] = useState(true);

  useEffect(() => {
    const bloco = document.querySelector('.bloco-9-3');
    const items = bloco.querySelectorAll('.bloco-9-3-item'); // Seleciona os itens individuais
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('scroll-animate');
          observer.unobserve(entry.target); // Remove a observação após a animação inicial
        }
      });
    }, { threshold: 0.2 });

    items.forEach((item, index) => {
      item.style.transitionDelay = `${index * 0.2}s`; // Aplica um atraso na transição para cada item
      observer.observe(item);
    });

    return () => observer.disconnect();
  }, []);

  const handleToggle = (value) => {
    setIsWithBitwoei(value);
  };

  return (
    <section className="bloco-9-3">
 
        
      <div className="toggle-section bloco-9-3-item">
      <button
          className={`toggle-button ${!isWithBitwoei ? 'active' : ''}`}
          onClick={() => handleToggle(false)}
        >
          Sem Bitwoei
        </button>
        <button
          className={`toggle-button ${isWithBitwoei ? 'active' : ''}`}
          onClick={() => handleToggle(true)}
        >
          Com Bitwoei
        </button>
       
        <div className="content-section bloco-9-3-item">
          {isWithBitwoei ? (
            <div className="content-with-bitwoei">
        <img src={cb} alt="Descrição da Imagem" />
            </div>
          ) : (
            <div className="content-without-bitwoei">
        <img src={sb} alt="Descrição da Imagem" />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Bloco9_3;
