import React, { useState, useEffect, useRef } from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';
import { FaUserPlus, FaSignInAlt } from 'react-icons/fa';
import IconSvg from '../../assets/svg/icon.svg';
import ptBrIcon from '../../assets/images/ptbr.png';
import ptPtIcon from '../../assets/images/ptpt.png';
import logo from '../../assets/images/logo.png';


const Navbar = () => {
  const [activeMenu, setActiveMenu] = useState(null);
  const [isLanguageDropdownOpen, setIsLanguageDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Fechar o dropdown ao clicar fora
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsLanguageDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleMouseEnter = (menu) => {
    setActiveMenu(menu);
  };

  const handleMouseLeave = () => {
    setActiveMenu(null);
  };

  const toggleLanguageDropdown = () => {
    setIsLanguageDropdownOpen(!isLanguageDropdownOpen);
  };

  return (
    <header className="navbar">
      <div className="logo">
      <img src={logo} alt="Bitwoei Logo" />
      </div>
      <nav className="nav-links">
        <ul>
          <li className="nav-item">
            <Link to="/">Início</Link>
          </li>
          <li
            className="nav-item has-submenu"
            onMouseEnter={() => handleMouseEnter('solucoes')}
            onMouseLeave={handleMouseLeave}
          >
            <a href="#">
              Soluções <span className="arrow"></span>
            </a>
            {activeMenu === 'solucoes' && (
              <div className="dropdown-content">
                <span className="dropdown-item">
                  <img src={IconSvg} alt="Icon" className="submenu-icon" />
                  <Link to="/checkout">Checkout</Link>
                </span>
                <span className="dropdown-item">
                  <img src={IconSvg} alt="Icon" className="submenu-icon" />
                  <a href="#link-pagamento">Criar Link de Pagamento</a>
                </span>
                <span className="dropdown-item">
                  <img src={IconSvg} alt="Icon" className="submenu-icon" />
                  <a href="#pagamentos">Pagamentos</a>
                </span>
                <span className="dropdown-item">
                  <img src={IconSvg} alt="Icon" className="submenu-icon" />
                  <a href="#qr-pix">Cobrar com QR ou PIX</a>
                </span>
              </div>
            )}
          </li>
          <li className="nav-item">
            <Link to="/developers">Developers</Link>
          </li>
          <li className="nav-item">
            <Link to="/taxas">Taxas</Link>
          </li>
          <li
            className="nav-item has-submenu"
            onMouseEnter={() => handleMouseEnter('empresa')}
            onMouseLeave={handleMouseLeave}
          >
            <a href="#">
              Empresa <span className="arrow"></span>
            </a>
            {activeMenu === 'empresa' && (
              <div className="dropdown-content">
                <span className="dropdown-item">
                  <img src={IconSvg} alt="Icon" className="submenu-icon" />
                  <Link to="/about">Sobre Nós</Link>
                </span>
                <span className="dropdown-item">
                  <img src={IconSvg} alt="Icon" className="submenu-icon" />
                  <a href="#historia">História</a>
                </span>
              </div>
            )}
          </li>
        </ul>
      </nav>
      <div className="navbar-buttons-container">
        <Link to="/login" className="link-button">
          <button className="navbar-login-btn">
            <FaSignInAlt className="button-icon" /> Login
          </button>
        </Link>
        <Link to="/createAccount" className="link-button">
          <button className="navbar-create-account-btn">
            <FaUserPlus className="button-icon" /> Criar uma Conta
          </button>
        </Link>
        <div className="language-dropdown-container" ref={dropdownRef}>
          <button className="language-button" onClick={toggleLanguageDropdown}>
            <img src={ptBrIcon} alt="Portuguese" className="language-icon" />
            <span className="arrow"></span> {/* Adicionando a seta */}
          </button>
          {isLanguageDropdownOpen && (
            <div className="language-dropdown">
              <div className="language-option">
                <img src={ptBrIcon} alt="Português" className="language-option-icon" />
              </div>
              <div className="language-option">
                <img src={ptPtIcon} alt="Português Portugal" className="language-option-icon" />
              </div>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Navbar;
