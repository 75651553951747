import React from 'react';
import './Bloco2.css'; // Certifique-se de criar e ajustar o caminho do CSS

const Bloco2 = () => {
  return (
    <div className="bloco2-container">
      <div className="bloco2-content">
        <h1 className="bloco2-title">A maior plataforma de serviços financeiros</h1>
        <p className="bloco2-text">para gestão de caixa e automatização dos meios de pagamento</p>
        <button className="bloco2-button">Quero contratar</button>
      </div>
    </div>
  );
};

export default Bloco2;
