import React, { useEffect, useState } from 'react';
import './Bloco4.css';

const Bloco4 = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const bloco4Position = document.getElementById('bloco4').getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      if (bloco4Position < windowHeight - 100) {
        setIsVisible(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div id="bloco4" className="bloco4-container">
      <h2 className="bloco4-title">Soluções customizadas para todos os tamanhos de negócios</h2>
      <div className={`bloco4-cards ${isVisible ? 'show-cards' : ''}`}>
        <div className="bloco4-card">
          <img src="/assets/images/large-business.jpg" alt="Grandes negócios" />
          <h3>Grandes Negócios</h3>
          <ul>
            <li>Soluções robustas para altas demandas de transações</li>
            <li>Ferramentas avançadas para controle e relatórios</li>
            <li>Suporte dedicado para integração personalizada</li>
          </ul>
        </div>
        <div className="bloco4-card">
          <img src="/assets/images/medium-business.jpg" alt="Médias empresas" />
          <h3>Médias Empresas</h3>
          <ul>
            <li>APIs flexíveis para integração com diversos sistemas</li>
            <li>Relatórios detalhados para otimização de processos</li>
            <li>Atendimento rápido para soluções de problemas</li>
          </ul>
        </div>
        <div className="bloco4-card">
          <img src="/assets/images/startup.jpg" alt="Startups" />
          <h3>Startups</h3>
          <ul>
            <li>Foco em crescimento com soluções escaláveis</li>
            <li>Atendimento ágil para adaptação às mudanças</li>
            <li>Ferramentas para gestão simplificada de pagamentos</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Bloco4;
