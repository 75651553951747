import React, { useEffect } from 'react';
import './Bloco4.css';
import backgroundImage from '../../assets/images/link.svg'; // Caminho ajustado para a nova imagem


const Bloco4 = () => {
  useEffect(() => {
    const bloco = document.querySelector('.bloco-4');
    const items = bloco.querySelectorAll('.bloco-4-item');
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('scroll-animate');
          observer.unobserve(entry.target);
        }
      });
    }, { threshold: 0.2 });

    items.forEach((item, index) => {
      item.style.transitionDelay = `${index * 0.2}s`;
      observer.observe(item);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <section className="bloco-4">
      <div className="bloco-4-content">
        <div className="bloco-4-text bloco-4-item">
          <h2>LINK DE PAGAMENTO PERSONALIZADO</h2>
          <p>
            Com o Bitwoei Pay, você cria links de pagamento personalizados para seus clientes com
            facilidade e rapidez. Ideal para e-commerces, prestadores de serviços e outros segmentos.
          </p>
          <p>
            Simplifique as transações, oferecendo uma experiência de pagamento flexível e segura,
            sem integrações complexas.
          </p>
          <p>
            Nossa tecnologia garante maior conversão, praticidade e segurança em cada venda, tudo
            com a confiabilidade que você precisa.
          </p>
          <button className="create-account-btn bloco-4-item">
Saiba Mais
</button>
       
        </div>
        <div className="bloco-4-image bloco-4-item">
          <img src={backgroundImage} alt="Imagem de exemplo de link personalizado" />
        </div>
      </div>
    </section>
  );
};

export default Bloco4;
