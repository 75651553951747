import React, { useState } from 'react';
import './Bloco10.css';

const Bloco10 = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleQuestion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqItems = [
    {
      question: 'O que é o Bitwoei Pay?',
      answer: 'O Bitwoei Pay é uma plataforma de pagamento que permite gerenciar recebimentos, cobranças e vendas em um só lugar, oferecendo segurança e praticidade para o seu negócio.'
    },
    {
      question: 'Quais métodos de pagamento são aceitos?',
      answer: 'O Bitwoei Pay aceita cartões de crédito, débito, PIX, boletos bancários, transferências e MB WAY, permitindo flexibilidade para você e seus clientes.'
    },
    {
      question: 'Como faço para integrar o Bitwoei Pay ao meu sistema?',
      answer: 'A integração é simples e rápida. Você pode utilizar nossa API ou os plugins disponíveis para as principais plataformas de ecommerce.'
    },
    {
      question: 'Existe suporte ao cliente?',
      answer: 'Sim, oferecemos suporte especializado 24/7 para resolver suas dúvidas e auxiliar em qualquer necessidade.'
    },
    {
      question: 'Quais as taxas cobradas pelo Bitwoei Pay?',
      answer: 'Trabalhamos com taxas competitivas e transparentes, sem cobranças ocultas. Consulte nosso site para mais detalhes.'
    },
    {
      question: 'Posso gerenciar minhas vendas com o Bitwoei Pay?',
      answer: 'Sim, o Bitwoei Pay oferece um painel de controle completo para gerenciar vendas, relatórios financeiros e recebimentos.'
    },
    {
      question: 'É seguro usar o Bitwoei Pay?',
      answer: 'Sim, utilizamos tecnologias de criptografia avançadas para garantir a segurança de todas as transações e dados dos seus clientes.'
    }
  ];

  return (
    <section className="bloco-10">
      <h2 className="bloco-10-title">Perguntas Frequentes</h2>
      <div className="faq-container">
        {faqItems.map((item, index) => (
          <div key={index} className="faq-item">
            <div
              className={`faq-question ${activeIndex === index ? 'active' : ''}`}
              onClick={() => toggleQuestion(index)}
            >
              {item.question}
              <span className="faq-icon">{activeIndex === index ? '-' : '+'}</span>
            </div>
            {activeIndex === index && (
              <div className="faq-answer">
                {item.answer}
              </div>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default Bloco10;
