import React, { useState, useEffect } from 'react';
import './CreateAccount.css'; // Certifique-se de que o caminho está correto
import logo from '../../assets/images/logo.png';
import Preloader from '../Preloader/Preloader'; // Certifique-se de que o Preloader está importado corretamente

const CreateAccount = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 800); // Tempo de carregamento ajustável

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <Preloader />; // Exibe o Preloader enquanto isLoading é true
  }

  return (
    <div className="create-account-page">
      <div className="create-account-container">
        <div className="logo-container">
        <img src={logo} alt="Bitwoei Logo" className="bitwoei-logo" />
        </div>
        <form className="create-account-form">
          <div className="form-group">
            <label htmlFor="name">Nome Completo</label>
            <input type="text" id="name" name="name" placeholder="Digite seu nome completo" />
          </div>
          <div className="form-group">
            <label htmlFor="email">E-mail</label>
            <input type="email" id="email" name="email" placeholder="Digite seu e-mail" />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Celular</label>
            <input type="tel" id="phone" name="phone" placeholder="Digite seu celular" />
          </div>
          <div className="form-group">
            <label htmlFor="password">Senha</label>
            <input type="password" id="password" name="password" placeholder="Digite sua senha" />
          </div>
          <button type="submit" className="create-account-button">Criar Conta</button>
        </form>
      </div>
    </div>
  );
};

export default CreateAccount;
