import React, { useEffect } from 'react';
import './Bloco9.css';
import { FaUserPlus } from 'react-icons/fa';


const Bloco9 = () => {
  const quadrantes = [
    {
      icon: 'fas fa-eye',
      title: 'Sem Custos Ocultos',
      description: 'Transparência total nas tarifas. Você sabe exatamente o que está pagando.',
    },
    {
      icon: 'fas fa-globe',
      title: 'Cobertura Global',
      description: 'Aceite pagamentos de qualquer lugar do mundo. Expanda seu alcance e conquiste novos mercados sem complicações.',
    },
    {
      icon: 'fas fa-chart-line',
      title: 'Gestão Simplificada',
      description: 'Painel completo e intuitivo para gerenciar suas finanças. Controle total sobre seus recebimentos e relatórios detalhados.',
    },
    {
      icon: 'fas fa-money-bill-alt',
      title: 'Tarifas Competitivas',
      description: 'Pague menos para receber mais! Nossas tarifas são justas e transparentes, ajudando você a economizar e aumentar sua margem de lucro.',
    },
  ];

  useEffect(() => {
    const bloco = document.querySelector('.bloco-9');
    const items = bloco.querySelectorAll('.bloco-9-item');
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('scroll-animate');
          observer.unobserve(entry.target);
        }
      });
    }, { threshold: 0.2 });

    items.forEach((item, index) => {
      item.style.transitionDelay = `${index * 0.2}s`;
      observer.observe(item);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <section className="bloco-9">
      <div className="bloco-9-left bloco-9-item">
        <h2 className="bloco-9-title">Por que escolher o <br></br>Bitwoei Pay?</h2>
        <p className="bloco-9-description">
          Além de ser apenas um Gateway de pagamento, é a solução ideal para negócios de todos os tamanhos, do pequeno empreendedor ao grande varejista. Com taxas competitivas e ferramentas integradas, ele simplifica sua operação e potencializa seus resultados.
        </p>
        <button className="bloco-9-button">
  Sobre Nós
</button>
      </div>
      <div className="bloco-9-right bloco-9-item">
        {quadrantes.map((quadrante, index) => (
          <div key={index} className="quadrante-9 bloco-9-item">
            <div className="quadrante-9-icon">
              <i className={quadrante.icon}></i>
            </div>
            <div className="quadrante-9-title">{quadrante.title}</div>
            <p className="quadrante-9-description">{quadrante.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Bloco9;
