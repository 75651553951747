import React, { useEffect, useRef, useState } from 'react';
import './Bloco3.css';

const Bloco3 = () => {
  const [titleVisible, setTitleVisible] = useState(false);
  const [textVisible, setTextVisible] = useState(false);
  const blocoRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setTitleVisible(true);
          setTimeout(() => setTextVisible(true), 2000); // Delay para começar a digitar o texto após o título
        }
      },
      { threshold: 0.5 }
    );

    if (blocoRef.current) {
      observer.observe(blocoRef.current);
    }

    return () => {
      if (blocoRef.current) {
        observer.unobserve(blocoRef.current);
      }
    };
  }, []);

  return (
    <div className="bloco3-container-renomeado" ref={blocoRef}>
      <h1 className={`bloco3-title-renomeado ${titleVisible ? 'typing-title-renomeado' : ''}`}>
        Nossa história
      </h1>
      <p className={`bloco3-text-renomeado ${textVisible ? 'typing-text-renomeado' : ''}`}>
        A história da Bitwoei começou com a ideia de criar um gateway de pagamentos que resolvesse problemas recorrentes
        no mercado, como altas taxas cobradas por outras soluções e falhas de segurança que impactavam os recebimentos
        de clientes.
        <br /><br />
        Os fundadores perceberam que muitas soluções existentes não ofereciam a transparência e eficiência necessárias
        para garantir a máxima aprovação de pagamentos e a segurança desejada. Assim nasceu a Bitwoei, com o propósito
        de oferecer taxas mais justas e um sistema robusto que minimize riscos de falhas nos pagamentos.
        <br /><br />
        Desde seu lançamento, a Bitwoei já processou milhares de transações, focando em oferecer uma experiência de
        pagamento ágil, segura e econômica para seus usuários. Com inovação e um forte compromisso com seus clientes, a
        plataforma se consolidou como uma solução confiável e transparente.
        <br /><br />
        Bitwoei continua crescendo, desenvolvendo novas soluções para atender às necessidades do mercado, sempre com o
        objetivo de proporcionar a melhor experiência de pagamento possível.
      </p>
    </div>
  );
};

export default Bloco3;
