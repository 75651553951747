import React, { useEffect } from 'react';
import './Bloco8.css';

import visaIcon from '../../assets/icons/visa.png';
import mastercardIcon from '../../assets/icons/mastercard.png';
import amexIcon from '../../assets/icons/amex.png';
import eloIcon from '../../assets/icons/elo.png';
import pixIcon from '../../assets/icons/pix.png';
import boletoIcon from '../../assets/icons/boleto.png';
import mbwayIcon from '../../assets/icons/mbway.png';
import applepayIcon from '../../assets/icons/applepay.png';

const Bloco8 = () => {
  useEffect(() => {
    const bloco = document.querySelector('.bloco-8');
    const items = bloco.querySelectorAll('.bloco-8-item');
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('scroll-animate');
          observer.unobserve(entry.target);
        }
      });
    }, { threshold: 0.2 });

    items.forEach((item, index) => {
      item.style.transitionDelay = `${index * 0.2}s`;
      observer.observe(item);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <section className="bloco-8">
      <h2 className="bloco-8-title bloco-8-item">Aceite vários meios de pagamento e venda parcelado sem acréscimo</h2>
      <div className="payment-icons-container bloco-8-item">
        <div className="payment-row bloco-8-item">
          <img src={visaIcon} alt="Visa" />
          <img src={mastercardIcon} alt="MasterCard" />
          <img src={amexIcon} alt="American Express" />
          <img src={eloIcon} alt="Elo" />
          <img src={pixIcon} alt="Pix" />
          <img src={boletoIcon} alt="Boleto" />
          <img src={mbwayIcon} alt="MB Way" />
        </div>
        <p className="payment-description bloco-8-item">Carteiras Digitais para facilitar suas transações</p>
        <div className="payment-row bloco-8-item">
          <img src={applepayIcon} alt="Apple Pay" />
        </div>
      </div>
    </section>
  );
};

export default Bloco8;
