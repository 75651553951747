import React, { useEffect } from 'react';
import './Bloco7.css';

const Bloco7 = () => {
  useEffect(() => {
    const bloco = document.querySelector('.bloco-7');
    const items = bloco.querySelectorAll('.bloco-7-item');
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('scroll-animate');
          observer.unobserve(entry.target);
        }
      });
    }, { threshold: 0.2 });

    items.forEach((item, index) => {
      item.style.transitionDelay = `${index * 0.2}s`;
      observer.observe(item);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <section className="bloco-7">
      <h2 className="bloco-7-title bloco-7-item">
        A escolha inteligente para pagamentos online
      </h2>
      <p className="bloco-7-description bloco-7-item">
        Receba suas vendas com a Bitwoei e maximize seus resultados hoje. Faça seu cadastro sem nenhuma cobrança de taxa inicial e comece a faturar ainda mais!
      </p>
      <div className="bloco-7-cards-container bloco-7-item">
        <div className="bloco-7-card bloco-7-item">
          <i className="fas fa-credit-card bloco-7-icon"></i>
          <h3>Cartão de crédito</h3>
          <p className="bloco-7-rate">A partir de</p>
          <p className="bloco-7-value">3,49%</p>
        </div>
        <div className="bloco-7-card bloco-7-item">
          <i className="fas fa-file-invoice bloco-7-icon"></i>
          <h3>Boleto</h3>
          <p className="bloco-7-rate">A partir de</p>
          <p className="bloco-7-value">R$ 3,49</p>
        </div>
        <div className="bloco-7-card bloco-7-item">
          <i className="fas fa-money-bill-wave bloco-7-icon"></i>
          <h3>Pix</h3>
          <p className="bloco-7-rate">A partir de</p>
          <p className="bloco-7-value">0,99%</p>
        </div>
      </div>
      <p className="bloco-7-call-to-action bloco-7-item">
        Seu negócio fatura mais de <span className="highlight">R$ 100 mil por mês?</span> Crie sua conta gratuita e um de nossos especialistas entrará em contato para negociar a melhor taxa do mercado.
      </p>
      <br></br><br></br>
      <div className="bloco-7-buttons bloco-7-item">
        <button className="bloco-7-button">
          CONFIRA NOSSAS TAXAS
        </button>
        <button className="bloco-7-button primary">
          <i className="fas fa-user-plus bloco-7-button-icon"></i> CRIE SUA CONTA
        </button>
      </div>
    </section>
  );
};

export default Bloco7;
