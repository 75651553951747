import React, { useEffect } from 'react';
import './Bloco9_2.css';

const Bloco9_2_Custom = () => {
  useEffect(() => {
    const items = document.querySelectorAll('.quadrante-custom');
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('scroll-animate');
          observer.unobserve(entry.target);
        }
      });
    }, { threshold: 0.2 });

    items.forEach((item, index) => {
      item.style.transitionDelay = `${index * 0.2}s`;
      observer.observe(item);
    });

    return () => observer.disconnect();
  }, []);

  const quadrantes = [
    {
      icon: 'fas fa-globe',
      title: 'Facilidade de Uso',
      miniTitle: 'Tão simples quanto descascar uma mexerica.',
      description: 'Integrar e usar o Bitwoei Pay é rápido e fácil, sem complicações técnicas.',
    },
    {
      icon: 'fas fa-chart-line',
      title: 'Versatilidade',
      miniTitle: 'Uma solução para diferentes segmentos e culturas.',
      description: 'Seja qual for o seu mercado, Bitwoei Pay se adapta às suas necessidades.',
    },
    {
      icon: 'fas fa-shield-alt',
      title: 'Segurança Avançada',
      miniTitle: 'Proteção para você e seus clientes.',
      description: 'Transações com tecnologia de ponta e total confiabilidade.',
    },
    {
      icon: 'fas fa-seedling',
      title: 'Crescimento do Negócio',
      miniTitle: 'Feito para o seu sucesso.',
      description: 'Reduza custos, aumente vendas e alcance novos clientes com Bitwoei Pay.',
    },
  ];

  return (
    <section className="bloco-9-2-custom">
      <div className="bloco-9-2-custom-grid">
        {quadrantes.map((quadrante, index) => (
          <div key={index} className="quadrante-custom bloco-9-2-item">
            <div className="icon-custom-placeholder">
              <i className={quadrante.icon}></i>
            </div>
            <h3>{quadrante.title}</h3>
            <h4 className="mini-title">{quadrante.miniTitle}</h4>
            <p>{quadrante.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Bloco9_2_Custom;
