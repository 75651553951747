import React from 'react';
import './Bloco6.css';

const Bloco6 = () => {
  return (
    <div className="bloco6-container">
      <h2 className="bloco6-title">Por que escolher nossa solução?</h2>
      <div className="bloco6-items">
        <div className="bloco6-item">
          <div className="bloco6-icon-wrapper">
            <svg height="48" width="48" xmlns="http://www.w3.org/2000/svg" fill="#6c63ff">
              <circle cx="24" cy="24" r="20" stroke="#6c63ff" strokeWidth="2" fill="none" />
              <circle cx="24" cy="24" r="10" stroke="none" fill="#6c63ff" />
            </svg>
          </div>
          <h3>Segurança</h3>
          <p>
            Proporcionamos uma solução robusta com sistemas antifraude e segurança avançada para todos os pagamentos.
          </p>
        </div>
        <div className="bloco6-item">
          <div className="bloco6-icon-wrapper">
            <svg height="48" width="48" xmlns="http://www.w3.org/2000/svg" fill="#6c63ff">
              <circle cx="24" cy="24" r="20" stroke="#6c63ff" strokeWidth="2" fill="none" />
              <rect x="17" y="17" width="14" height="14" stroke="none" fill="#6c63ff" />
            </svg>
          </div>
          <h3>Mais Vendas</h3>
          <p>
            Nossas soluções ajudam a maximizar conversões, garantindo mais vendas com opções flexíveis de pagamento.
          </p>
        </div>
        <div className="bloco6-item">
          <div className="bloco6-icon-wrapper">
            <svg height="48" width="48" xmlns="http://www.w3.org/2000/svg" fill="#6c63ff">
              <circle cx="24" cy="24" r="20" stroke="#6c63ff" strokeWidth="2" fill="none" />
              <polygon points="24,10 14,38 34,38" stroke="none" fill="#6c63ff" />
            </svg>
          </div>
          <h3>Facilidade</h3>
          <p>
            Gerencie todos os seus pagamentos de forma centralizada, com fácil integração e usabilidade.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Bloco6;
