import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar/Navbar'; // Ajuste o caminho se necessário
import './About.css'; // Estilos específicos para a página About
import Preloader from '../Preloader/Preloader'; // Importa o Preloader corretamente

const About = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 800); // Ajuste o tempo conforme necessário

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <Preloader />; // Exibe o Preloader enquanto isLoading é true
  }

  return (
    <>
      <Navbar />
      <section className="about-section">
        <div className="about-container">
          <div className="about-text">
            <h1>Quem Somos?</h1>
            <p>
              A Bitwoei é uma plataforma de pagamentos online para negócios digitais, com foco na maximização dos resultados.
            </p>
          </div>
          <div className="about-image">
            <img src="/assets/images/about-image.png" alt="Equipe trabalhando" />
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
