import React, { useEffect } from 'react';
import './Bloco4_1.css';
import backgroundImage from '../../assets/images/d0.png'; // Caminho ajustado para a imagem


const Bloco4_1 = () => {
  useEffect(() => {
    const bloco = document.querySelector('.bloco-4-1');
    const items = bloco.querySelectorAll('.bloco-4-1-item'); // Seleciona os itens individuais
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('scroll-animate');
          observer.unobserve(entry.target); // Remove a observação após a animação inicial
        }
      });
    }, { threshold: 0.2 });

    items.forEach((item, index) => {
      item.style.transitionDelay = `${index * 0.2}s`; // Aplica um atraso na transição para cada item
      observer.observe(item);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <section className="bloco-4-1">
      <div className="bloco-4-1-content">
        <div className="bloco-4-1-image bloco-4-1-item">
        <img src={backgroundImage}  alt="Imagem de exemplo" />
        </div>
        <div className="bloco-4-1-text bloco-4-1-item">
          <h2>LIQUIDAÇÃO EM D+0</h2>
          <p>
          Com a funcionalidade de Liquidação em D+0 do Bitwoei Pay, seu negócio tem acesso 
          aos valores das vendas no mesmo dia em que as transações são realizadas. Essa rapidez 
          proporciona maior controle sobre o fluxo de caixa, permitindo investimentos imediatos, 
          pagamentos a fornecedores e maior agilidade financeira.</p>
          <p>Ao eliminar os longos prazos de 
          compensação, você ganha em eficiência e competitividade, garantindo uma experiência 
          otimizada tanto para sua empresa quanto para seus clientes.</p> 
          
          <p>Nossa solução combina 
          velocidade e segurança, assegurando que cada transação seja realizada com total 
          transparência e confiabilidade.
          </p>
          <button className="create-account-btn bloco-4-1-item">
Saiba Mais
</button>          </div>
      </div>
    </section>
  );
};

export default Bloco4_1;
