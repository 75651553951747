import React, { useEffect, useState } from 'react';
import './Bloco5.css';
import CustomIcon from '../../assets/svg/icon.svg'; // Importando o SVG

const Bloco5 = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const bloco5Position = document.getElementById('bloco5').getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      if (bloco5Position < windowHeight - 100) {
        setIsVisible(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div id="bloco5" className="bloco5-container">
      <div className={`bloco5-image ${isVisible ? 'fade-in' : ''}`}>
        <img src="/assets/images/custom-image.jpg" alt="Custom illustration" />
      </div>
      <div className="bloco5-content">
        <h2 className={`bloco5-title ${isVisible ? 'fade-in' : ''}`}>Ajudamos você a crescer</h2>
        <div className={`bloco5-item ${isVisible ? 'fade-in-item' : ''}`}>
          <img src={CustomIcon} alt="Custom icon" height="24" width="24" />
          <p>Oferecemos soluções para aumentar a aprovação dos pagamentos de forma eficiente.</p>
        </div>
        <div className={`bloco5-item ${isVisible ? 'fade-in-item' : ''}`}>
          <img src={CustomIcon} alt="Custom icon" height="24" width="24" />
          <p>Recuperamos clientes que deixaram o checkout antes de finalizar a compra.</p>
        </div>
        <div className={`bloco5-item ${isVisible ? 'fade-in-item' : ''}`}>
          <img src={CustomIcon} alt="Custom icon" height="24" width="24" />
          <p>Maximizamos seu faturamento com estratégias para melhorar o ticket médio.</p>
        </div>
      </div>
    </div>
  );
};

export default Bloco5;
