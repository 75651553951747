import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import './Footer.css';
import { FaRegCopyright } from 'react-icons/fa';

// import logo from '';
// import sealsImage from '';



function Footer() {
  return (
    <footer className="footer">
      <div className="footer-columns">
        <div className="column">
          <h3>Explore</h3>
          <ul>
            <li><a href="#">Início</a></li>
            <li><a href="#">Blog</a></li>
            <li><a href="#">Taxas</a></li>
            <li><a href="#">Integrações</a></li>
          </ul>
        </div>
        <div className="column">
          <h3>Sobre</h3>
          <ul>
            <li><a href="#">Trabalhe Conosco</a></li>
            <li><a href="#">Quem Somos</a></li>
            <li><a href="#">Por Que Bitwoei</a></li>
            <li><a href="#">Termos de Uso</a></li>
          </ul>
        </div>
        <div className="column">
          <h3>Ajuda</h3>
          <ul>
            <li><a href="#">Contato</a></li>
            <li><a href="#">Central de Ajuda</a></li>
          </ul>
        </div>
        {/* Comente ou remova esta parte se você não tiver `sealsImage` */}
        {/* <div className="seals">
          <img src={sealsImage} alt="Selos do Site" />
        </div> */}
      </div>
      <div className="footer-bottom">
        {/* Comente ou remova esta parte se você não tiver `logo` */}
        {/* <img src={logo} alt="Logo Bitwoei" className="footer-logo" /> */}
        <div className="social-icons">
          <a href="#"><FontAwesomeIcon icon={faLinkedin} size="2x" /></a>
          <a href="#"><FontAwesomeIcon icon={faInstagram} size="2x" /></a>
          <a href="#"><FontAwesomeIcon icon={faYoutube} size="2x" /></a>
        </div>
        <p className="company-info">
  <FaRegCopyright className="copy-icon" /> 2024 Bitwoei Pay. Todos os direitos reservados.
</p>

      </div>
    </footer>
  );
}


export default Footer;