import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';

import Navbar from './components/Navbar/Navbar';
import Home from './components/Home/Home';
import Bloco2 from './components/Home/Bloco2';
import Bloco3 from './components/Home/Bloco3';
import Bloco4 from './components/Home/Bloco4';
import Bloco4_1 from './components/Home/Bloco4_1'; // Adicionado Bloco4_1
import Bloco6 from './components/Home/Bloco6';
import Bloco7 from './components/Home/Bloco7'; // Adicionado Bloco7
import Bloco8 from './components/Home/Bloco8'; // Adicionado Bloco8
import Bloco9 from './components/Home/Bloco9'; // Adicionado Bloco9
import Bloco9_1 from './components/Home/Bloco9_1'; // Adicionado Bloco9_1
import Bloco9_2 from './components/Home/Bloco9_2'; // Adicionado Bloco9_2
import Bloco9_3 from './components/Home/Bloco9_3'; // Adicionado Bloco9_3
import Bloco10 from './components/Home/Bloco10'; // Adicionado Bloco10
import Footer from './components/Footer/Footer'; // Adicionado Footer
import About from './components/About/About'; // Importando a página About
import Bloco2About from './components/About/Bloco2'; // Importando Bloco2 do About
import Bloco3About from './components/About/Bloco3'; // Importando Bloco3 do About
import Bloco4About from './components/About/Bloco4';
import Bloco5About from './components/About/Bloco5';
import Bloco6About from './components/About/Bloco6';
import CreateAccount from './components/CreateAccount/CreateAccount'; // Importando CreateAccount
import Login from './components/Login/Login'; // Importando Login

import Preloader from './components/Preloader/Preloader'; // Importando o Preloader
import './App.css';

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 800);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <Preloader />;
  }

  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Home />
                <Bloco2 />
                <Bloco3 />
                <Bloco4 />
                <Bloco4_1 />
                <Bloco6 />
                <Bloco7 />
                <Bloco8 />
                <Bloco9 />
                <Bloco9_1 />
                <Bloco9_2 />
                <Bloco9_3 />
                <Bloco10 />
                <Footer />
              </>
            }
          />
          <Route
            path="/about"
            element={
              <>
                <About />
                <Bloco2About />
                <Bloco3About />
                <Bloco4About />
                <Bloco5About />
                <Bloco6About />
                <Footer />
              </>
            }
          />
          <Route
            path="/createAccount"
            element={<CreateAccount />} // Adicionando a rota CreateAccount
          />
          <Route
            path="/login"
            element={<Login />} // Adicionando a rota Login
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
