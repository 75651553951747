import React, { useEffect } from 'react';
import './Bloco6.css';
import backgroundImage from '../../assets/images/link.png'; // Caminho ajustado para a imagem
import { FaUserPlus } from 'react-icons/fa';



const Bloco6 = () => {
  useEffect(() => {
    const bloco = document.querySelector('.bloco-6');
    const items = bloco.querySelectorAll('.bloco-6-item'); // Seleciona os itens individuais
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('scroll-animate');
          observer.unobserve(entry.target); // Remove a observação após a animação inicial
        }
      });
    }, { threshold: 0.2 });

    items.forEach((item, index) => {
      item.style.transitionDelay = `${index * 0.2}s`; // Aplica um atraso na transição para cada item
      observer.observe(item);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <section className="bloco-6">
      <div className="bloco-6-content">
        <div className="bloco-6-text bloco-6-item">
          <h2>Sua Conta Digital Completa          </h2>
          <p>
          Simplifique sua gestão financeira e impulsione seus resultados.<br></br>
Com o Bitwoei, você tem tudo o que precisa na palma da mão:

Visualize seu saldo atual em tempo real.<br></br>
Controle facilmente o valor a receber de suas vendas futuras.
Antecipe receitas disponíveis com apenas um <br></br>clique e tenha acesso imediato ao seu dinheiro.
Acompanhe suas vendas recentes e analise o desempenho <br></br>do seu negócio com transparência. 


          </p>
          <button className="custom-create-button bloco-6-item">
  <FaUserPlus className="button-icon" /> Criar Conta
</button>
        </div>
        <div className="bloco-6-image bloco-6-item">
        <img src={backgroundImage}  alt="Imagem de exemplo" />
        </div>
      </div>
    </section>
  );
};

export default Bloco6;
