import React from 'react';
import './Preloader.css';
import CustomIcon from '../../assets/svg/icon.svg'; // Importando seu SVG

const Preloader = () => {
  return (
    <div className="preloader-container">
      <img src={CustomIcon} alt="Preloader Icon" className="preloader-icon" />
    </div>
  );
};

export default Preloader;
