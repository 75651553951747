import React, { useEffect } from 'react';
import './Bloco2.css';
import { FaCreditCard, FaMobileAlt, FaHandshake, FaShieldAlt } from 'react-icons/fa'; // Importa ícones para os quadrantes

const Bloco2 = () => {
  // Adiciona a lógica de animação usando useEffect
  useEffect(() => {
    const quadrantes = document.querySelectorAll('.quadrante');
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('scroll-animate');
          observer.unobserve(entry.target); // Remove a observação após a animação inicial
        }
      });
    }, { threshold: 0.2 });

    quadrantes.forEach((quadrante) => observer.observe(quadrante));

    // Limpeza
    return () => observer.disconnect();
  }, []);

  return (
    <section className="bloco-2">
      <h2 className="bloco-2-title">SOLUÇÕES DE PAGAMENTO QUE FAZEM A DIFERENÇA</h2>
      <div className="quadrantes-container">
        <div className="quadrante">
          <FaCreditCard className="quadrante-icon" />
          <h3 className="quadrante-title">Pagamentos Diversificados</h3>
          <p className="quadrante-description">Receba com cartões, QR codes e mais, tudo com segurança.</p>
        </div>
        <div className="quadrante">
          <FaMobileAlt className="quadrante-icon" />
          <h3 className="quadrante-title">Soluções Móveis</h3>
          <p className="quadrante-description">Conecte-se com clientes através de pagamentos móveis.</p>
        </div>
        <div className="quadrante">
          <FaHandshake className="quadrante-icon" />
          <h3 className="quadrante-title">Parcerias Estratégicas</h3>
          <p className="quadrante-description">Facilite a colaboração e aumente suas receitas.</p>
        </div>
        <div className="quadrante">
          <FaShieldAlt className="quadrante-icon" />
          <h3 className="quadrante-title">Segurança em Primeiro Lugar</h3>
          <p className="quadrante-description">Transações protegidas com tecnologia de ponta.</p>
        </div>
      </div>
    </section>
  );
};

export default Bloco2;
