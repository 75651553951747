import React, { useEffect } from 'react';
import './Bloco3.css';
import { FaCheckCircle } from 'react-icons/fa'; // Ícone de exemplo, substitua conforme necessário
import backgroundImage from '../../assets/images/vantagens.png'; // Caminho ajustado para a imagem
import { FaArrowRight } from 'react-icons/fa';



const Bloco3 = () => {
  useEffect(() => {
    const bloco = document.querySelector('.bloco-3');
    const items = bloco.querySelectorAll('.bloco-3-item'); // Seleciona os itens individuais
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('scroll-animate');
          observer.unobserve(entry.target); // Remove a observação após a animação inicial
        }
      });
    }, { threshold: 0.2 });

    items.forEach((item, index) => {
      item.style.transitionDelay = `${index * 0.2}s`; // Aplica um atraso na transição para cada item
      observer.observe(item);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <section className="bloco-3">
      <div className="bloco-3-content bloco-3-item"> {/* Adiciona a classe bloco-3-item para animação */}
        <div className="bloco-3-image bloco-3-item">
        <img src={backgroundImage}  alt="Imagem de exemplo" />
        </div>
        <div className="bloco-3-text bloco-3-item">
          <h2>POR QUE ESCOLHER O BITWOEI?</h2>
          <ul className="bloco-3-list">
            <li className="bloco-3-item"><FaCheckCircle className="list-icon" /> Pagamentos Sem Bloqueios</li>
            <li className="bloco-3-item"><FaCheckCircle className="list-icon" /> Adaptação para Todos os Segmentos </li>
            <li className="bloco-3-item"><FaCheckCircle className="list-icon" /> Menores Taxas do Mercado</li>
            <li className="bloco-3-item"><FaCheckCircle className="list-icon" /> Checkout Personalizado</li>
            <li className="bloco-3-item"><FaCheckCircle className="list-icon" /> Suporte a Pagamentos Internacionais</li>
            <li className="bloco-3-item"><FaCheckCircle className="list-icon" /> Gestão de Cobranças</li>
          </ul>
          <button className="button-solucoes bloco-3-item">
   <FaArrowRight className="button-icon" /> Soluções
</button>

        </div>
      </div>
    </section>
  );
};

export default Bloco3;
