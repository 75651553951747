import React, { useState, useEffect } from 'react';
import './Login.css'; // Certifique-se de que o caminho está correto
import logo from '../../assets/images/logo.png';

import Preloader from '../Preloader/Preloader'; // Certifique-se de que o Preloader está importado corretamente

const Login = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 800); // Tempo de carregamento ajustável

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <Preloader />; // Exibe o Preloader enquanto isLoading é true
  }

  return (
    <div className="login-page">
      <div className="login-container">
        <div className="logo-container">
        <img src={logo} alt="Bitwoei Logo" className="bitwoei-logo" />
        </div>
        <form className="login-form">
          <div className="form-group">
            <label htmlFor="email">E-mail</label>
            <input type="email" id="email" name="email" placeholder="Digite seu e-mail" />
          </div>
          <div className="form-group">
            <label htmlFor="password">Senha</label>
            <input type="password" id="password" name="password" placeholder="Digite sua senha" />
          </div>
          <button type="submit" className="login-button">Entrar</button>
        </form>
      </div>
    </div>
  );
};

export default Login;
