import React, { useEffect } from 'react';
import './Bloco9_1.css';
import mexdes from '../../assets/images/mexdes.png';
import { FaUserPlus } from 'react-icons/fa';




const Bloco9_1 = () => {
  useEffect(() => {
    const bloco = document.querySelector('.bloco-9-1');
    const items = bloco.querySelectorAll('.bloco-9-1-item'); // Seleciona os itens individuais
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('scroll-animate');
          observer.unobserve(entry.target); // Remove a observação após a animação inicial
        }
      });
    }, { threshold: 0.2 });

    items.forEach((item, index) => {
      item.style.transitionDelay = `${index * 0.2}s`; // Aplica um atraso na transição para cada item
      observer.observe(item);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <section className="bloco-9-1">
      <div className="bloco-9-1-content">
        <div className="bloco-9-1-image bloco-9-1-item">
        <img src={mexdes} alt="Descrição da Imagem" />
        </div>
        <div className="bloco-9-1-text bloco-9-1-item">
          <h2>Cada Gomo, um Segmento</h2>
          <p>
          Assim como os gomos de uma mexerica, o Bitwoei Pay se adapta a diferentes segmentos do mercado. <p>Seja para serviços, assinaturas, e-commerce, marketplaces ou varejo, cada gomo representa uma solução integrada, pensada para simplificar e otimizar a gestão dos seus pagamentos. 🍊</p>
          </p>
          <button className="custom-button bloco-9-1-item">
  <FaUserPlus className="button-icon" /> Criar Conta
</button>
        </div>
      </div>
    </section>
  );
};

export default Bloco9_1;
